<template>
  <!-- Navbar-->
  <nav class="navbar-expand-md navbar fixed-top" id="navbar">
    <a class="navbar-brand" data-scroll="" href="#home-area">
      <!-- Navbar Logo-->
      <img class="img-fluid" src="img/logo.svg" alt=""
    /></a>
    <span
      class="navbar-menu ml-auto"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      role="button"
      ><span class="btn-line"></span
    ></span>
    <div
      class="collapse navbar-collapse order-1 order-lg-0"
      id="navbarSupportedContent"
    >
      <!-- Navbar menu-->
      <ul class="navbar-nav ml-auto">
        <li class="nav-item"><a class="nav-link" href="#about">About</a></li>
        <li class="nav-item">
          <a class="nav-link" href="#portfolio">Portfolio</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#contact">Contact</a>
        </li>
      </ul>
    </div>
  </nav>
  <Home></Home>
</template>

<script>
import Home from "./components/Home.vue";

export default {
  name: "App",
  components: {
    Home,
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
