<template>
  <!-- Home-->
  <section
    class="home-area element-cover-bg"
    id="home"
    style="background-image: url(img/home-bg.jpg)"
  >
    <div class="container h-100">
      <div class="row h-100 align-items-center justify-content-center">
        <div class="col-12 col-lg-8 home-content text-center">
          <h1 class="home-name">Ryan <span>Jacobs</span></h1>
          <h4 class="cd-headline clip home-headline">
            I’m a
            <span class="cd-words-wrapper single-headline"
              ><b class="is-visible">Videographer</b><b>Photographer</b
              ><b>Freelancer</b></span
            >
          </h4>
        </div>
      </div>
    </div>
    <div class="fixed-wrapper">
      <!-- Languages list-->
      <div class="fixed-block block-left">
        <ul class="list-unstyled languages-list">
          <li>
            <a href="#"><span class="single-language">ENG</span></a>
          </li>
        </ul>
      </div>

      <!-- Social media icons-->
      <div class="fixed-block block-right">
        <ul class="list-unstyled social-icons">
          <li>
            <a href="https://twitter.com/RJacobz" target="blank"
              ><i class="icon ion-logo-twitter"></i
            ></a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UClgM-QEvYZnSrP0XIe9DI4g"
              target="blank"
              ><i class="icon ion-logo-youtube"></i
            ></a>
          </li>
          <li>
            <a href="https://www.instagram.com/ryan_jacobs_01/" target="blank"
              ><i class="icon ion-logo-instagram"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
    Icons made by
    <a href="https://www.flaticon.com/authors/dinosoftlabs" title="DinosoftLabs"
      >DinosoftLabs</a
    >
    from
    <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
  </section>

  <!-- About lightbox-->
  <div class="lightbox-wrapper" id="about" data-simplebar>
    <div class="container">
      <div class="lightbox-close">
        <div class="close-btn" data-modal-close="">
          <span class="btn-line"></span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="lightbox-content">
            <div class="row">
              <div class="col-12">
                <div class="section-heading page-heading">
                  <p class="section-description">Find out more</p>
                  <h2 class="section-title">About Me</h2>
                  <div class="animated-bar"></div>
                </div>
              </div>
            </div>
            <!-- Info section-->
            <div class="info-section single-section">
              <div class="row align-items-center">
                <!-- Picture part-->
                <div class="col-12 col-lg-5 info-img">
                  <img
                    class="img-fluid img-thumbnail"
                    src="img/about-me.jpg"
                    alt="About Picture"
                  />
                </div>
                <!-- Content part-->
                <div class="col-12 col-lg-7 info-content">
                  <div class="content-block">
                    <h2 class="content-subtitle">Who am I?</h2>
                    <h6 class="content-title">
                      I'm Ryan Jacobs, a videographer/photographer from South
                      Africa
                    </h6>
                    <div class="content-description">
                      <p>
                        I started out with a passion for photography and soon
                        realised that I enjoyed creating memories with video. I
                        enjoy working on all types of projects that allow me to
                        both learn, and explore my passion for creating art even
                        further.
                      </p>
                    </div>
                    <address class="content-info">
                      <div class="row">
                        <div class="col-12 col-md-6 single-info">
                          <span>Name:</span>
                          <p>Ryan Jacobs</p>
                        </div>
                        <div class="col-12 col-md-6 single-info">
                          <span>Email:</span>
                          <p>
                            <a href="mailto:info@ryan-jacobs.co.za"
                              >info@ryan-jacobs.co.za</a
                            >
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 single-info">
                          <span>From:</span>
                          <p>Cape Town, SA</p>
                        </div>
                      </div>
                    </address>
                    <div class="d-block d-sm-flex align-items-center">
                      <ul class="list-unstyled list-inline content-follow">
                        <li class="list-inline-item">
                          <a href="https://twitter.com/RJacobz" target="blank"
                            ><i class="icon ion-logo-twitter"></i
                          ></a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="https://www.youtube.com/channel/UClgM-QEvYZnSrP0XIe9DI4g"
                            target="blank"
                            ><i class="icon ion-logo-youtube"></i
                          ></a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="https://www.instagram.com/ryan_jacobs_01/"
                            target="blank"
                            ><i class="icon ion-logo-instagram"></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Services section-->
            <div class="services-section single-section">
              <div class="row">
                <div class="col-12">
                  <div class="section-heading">
                    <p class="section-description">What I have to offer</p>
                    <h2 class="section-title">My Passion</h2>
                  </div>
                </div>
              </div>
              <div class="row">
                <!-- Single service-->
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="single-service">
                    <i class="icon service-icon ion-ios-videocam"></i>
                    <h6 class="service-title">Videography</h6>
                    <p class="service-description">
                      I always make sure to carry my camera and try and capture
                      something that catches my eye.
                    </p>
                  </div>
                </div>
                <!-- Single service-->
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="single-service">
                    <i class="icon service-icon ion-ios-camera"></i>
                    <h6 class="service-title">Photography</h6>
                    <p class="service-description">
                      I consider myself a half decent photographer. I mean, it's
                      not just point and shoot, is it?
                    </p>
                  </div>
                </div>
                <!-- Single service-->
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="single-service">
                    <i class="icon service-icon ion-logo-youtube"></i>
                    <h6 class="service-title">Reviews</h6>
                    <p class="service-description">
                      Sometimes I enjoy rambling on about my views on particular
                      topics, usually tech related.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- TODO: Add feedback section -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Portfolio lightbox-->
  <div class="lightbox-wrapper" id="portfolio" data-simplebar>
    <div class="container">
      <div class="lightbox-close">
        <div class="close-btn" data-modal-close="">
          <span class="btn-line"></span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="lightbox-content">
            <div class="row">
              <div class="col-12">
                <div class="section-heading page-heading">
                  <p class="section-description">
                    Showcasing some of my work
                  </p>
                  <h2 class="section-title">Portfolio</h2>
                  <div class="animated-bar"></div>
                </div>
              </div>
            </div>
            <!-- Portfolio section-->
            <div class="portfolio-section single-section">
              <div class="row">
                <!-- Filter nav-->
                <div class="col-12">
                  <ul
                    class="list-inline filter-control"
                    role="group"
                    aria-label="Filter Control"
                  >
                    <li class="list-inline-item tab-active" data-filter="*">
                      All
                    </li>
                  </ul>
                </div>
              </div>
              <!-- Thumbnail list-->
              <div class="portfolio-grid row">
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-1.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-1.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-2.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-2.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-3.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-3.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-4.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-4.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-5.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-5.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-6.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-6.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-7.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-7.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-8.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-8.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-9.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-9.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-10.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-10.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-11.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-11.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-12.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-12.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-13.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-13.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-14.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-14.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-15.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-15.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-16.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-16.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-17.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-17.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-18.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-18.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-19.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-19.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-20.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-20.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-21.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-21.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-22.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-22.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-23.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-23.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-24.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-24.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-25.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-25.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-26.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-26.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- NEW -->
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-27.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-27.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-28.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-28.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-29.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-29.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-30.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-30.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-31.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-31.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-32.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-32.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-33.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-33.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-34.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-34.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-35.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-35.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-42.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-42.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-37.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-37.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-38.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-38.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-39.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-39.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-40.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-40.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-41.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-41.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Single item-->
                <div class="single-item col-6 col-lg-4 videos">
                  <a
                    class="portfolio-item"
                    href="img/portfolio-36.jpg"
                    data-lightbox
                  >
                    <div class="portfolio-wrapper">
                      <img
                        class="img-fluid"
                        alt="Item"
                        src="img/portfolio-36.jpg"
                      />
                      <div class="item-content">
                        <h6 class="content-title">Image</h6>
                        <span class="content-more">View</span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- TODO: Add blog content -->

  <!-- Contact lightbox-->
  <div class="lightbox-wrapper" id="contact" data-simplebar>
    <div class="container">
      <div class="lightbox-close">
        <div class="close-btn" data-modal-close="">
          <span class="btn-line"></span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="lightbox-content">
            <div class="row">
              <div class="col-12">
                <div class="section-heading page-heading">
                  <p class="section-description">Feel free to contact me</p>
                  <h2 class="section-title">Get in Touch</h2>
                  <div class="animated-bar"></div>
                </div>
              </div>
            </div>
            <!-- Contact section-->
            <div class="contact-section single-section">
              <div class="row">
                <!-- Contact form-->
                <div class="col-12 col-lg-7">
                  <form
                    class="contact-form"
                    id="contact-form"
                    method="POST"
                    action="contact.php"
                  >
                    <h4 class="content-title">Message Me</h4>
                    <div class="row">
                      <div class="col-12 col-md-6 form-group">
                        <input
                          class="form-control"
                          id="contact-name"
                          type="text"
                          name="name"
                          placeholder="Name"
                          required=""
                        />
                      </div>
                      <div class="col-12 col-md-6 form-group">
                        <input
                          class="form-control"
                          id="contact-email"
                          type="email"
                          name="email"
                          placeholder="Email"
                          required=""
                        />
                      </div>
                      <div class="col-12 form-group">
                        <input
                          class="form-control"
                          id="contact-subject"
                          type="text"
                          name="subject"
                          placeholder="Subject"
                          required=""
                        />
                      </div>
                      <div class="col-12 form-group form-message">
                        <textarea
                          class="form-control"
                          id="contact-message"
                          name="message"
                          placeholder="Message"
                          rows="5"
                          required=""
                        ></textarea>
                      </div>
                      <div class="col-12 form-submit">
                        <button
                          class="btn button-main button-scheme"
                          id="contact-submit"
                          type="submit"
                        >
                          Send Message
                        </button>
                        <p class="contact-feedback"></p>
                      </div>
                    </div>
                  </form>
                </div>
                <!-- Contact info-->
                <div class="col-12 col-lg-5">
                  <div class="contact-info">
                    <h4 class="content-title">Contact Info</h4>
                    <p class="info-description">
                      I'm always up to take on projects which excite me. Feel
                      free to contact me and lets create some magic.
                    </p>
                    <ul class="list-unstyled list-info">
                      <li>
                        <div class="media align-items-center">
                          <span class="info-icon"
                            ><i class="icon ion-logo-ionic"></i
                          ></span>
                          <div class="media-body info-details">
                            <h6 class="info-type">Name</h6>
                            <span class="info-value">Ryan Jacobs</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="media align-items-center">
                          <span class="info-icon"
                            ><i class="icon ion-md-map"></i
                          ></span>
                          <div class="media-body info-details">
                            <h6 class="info-type">Location</h6>
                            <span class="info-value"
                              >Cape Town, South Africa</span
                            >
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="media align-items-center">
                          <span class="info-icon"
                            ><i class="icon ion-md-send"></i
                          ></span>
                          <div class="media-body info-details">
                            <h6 class="info-type">Email Me</h6>
                            <span class="info-value"
                              ><a href="mailto:info@ryan-jacobs.co.za"
                                >info@ryan-jacobs.co.za</a
                              ></span
                            >
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Home",
};
</script>
